import Transaction from '@/domain/Transaction';
import Location from '@/domain/Location';
import TransactionLine from '@/domain/TransactionLine';
import { TransactionStatus } from '@/domain/TransactionStatus';
import { getNumberOfDays } from '@/functions/date';
import { RuleFn, ValidationRules } from '@/validation/types';
import useStringFormatter from '@/composable/useStringFormatter';
import InventoryCategory from '@/domain/InventoryCategory';
import { getTranslation } from '@/services/TranslationService';
import coreStore from '@/store/CoreStore';

const { capitalizeFirstLetter } = useStringFormatter();

const { orderDateRangeStart, orderDateRangeEnd } = coreStore.getInstance().pemSettingsStore;

const toLocationRequiredRule: ValidationRules<Transaction> = {
    key: 'toLocation',
    name: 'core.domain.toLocation',
    required: true,
    rules: [(value: Location) => value?.id > 0 || capitalizeFirstLetter(getTranslation('core.validation.toLocationRequired'))],
};

const fromLocationRequiredRule: ValidationRules<Transaction> = {
    key: 'fromLocation',
    name: 'core.domain.fromLocation',
    required: true,
    rules: [(value: Location) => value?.id > 0 || capitalizeFirstLetter(getTranslation('core.validation.fromLocationRequired'))],
};

const partnerReferenceNumberRequiredRule: ValidationRules<Transaction> = {
    key: 'partnerReferenceNumber',
    name: 'core.domain.partnerReferenceNumber',
    required: true,
};

const oneItemIsRequiredRule: RuleFn = (value: Array<TransactionLine>) => value.length > 0 || getTranslation('core.validation.transactionDoesNotHaveAnyItems');

const defaultAddingQuantitysToLineItemsRule: RuleFn = (value: Array<TransactionLine>, transaction: Transaction) => {
    if (transaction.status >= TransactionStatus.PICKED) {
        const errMsg = getTranslation('core.validation.youMustEnterActualQuantityForAtLeast1Item');
        return value.some((l) => l.actualQuantity > 0) || errMsg;
    }
    const errMsg = getTranslation('core.validation.youMustEnterPlannedQuantityForAtLeast1Item');
    return value.some((l) => l.plannedQuantity > 0) || errMsg;
};

const quantitiesMustExceedScannedTagsRule: RuleFn = (value: Array<TransactionLine>, transaction: Transaction) => {
    const errMsg = getTranslation('core.validation.thereAreMoreTagsScannedThanQuantitiesEntered');
    return value.some((l) => (transaction.status >= TransactionStatus.PICKED ? l.actualQuantity : l.plannedQuantity) >= l.numberOfTags) || errMsg;
};

const oneLineMustHaveActualQuantityRule: RuleFn = (value: Array<TransactionLine>) => {
    const errMsg = getTranslation('core.validation.atLeastOneLineMustHaveActualQuantity');
    return value.some((x) => x.actualQuantity > 0) || errMsg;
};

const defaultOutboundRules: Array<ValidationRules<Transaction>> = [
    fromLocationRequiredRule,
    toLocationRequiredRule,
    {
        key: 'transactionLines',
        name: 'core.domain.transactionLines',
        required: true,
        rules: [oneItemIsRequiredRule, defaultAddingQuantitysToLineItemsRule, quantitiesMustExceedScannedTagsRule],
    },
];

const shipFromInventoryCategoryRequiredRule: ValidationRules<Transaction> = {
    key: 'inventoryCategory',
    name: 'core.domain.inventoryCategory',
    required: true,
    rules: [
        (value: InventoryCategory, transaction: Transaction) => {
            const errMsg = getTranslation('core.validation.youMustSelectAnInventoryCategory');
            return transaction.isInventoryCategorySet || errMsg;
        },
    ],
};

export const transactionOutboundFormRules: Array<ValidationRules<Transaction>> = [...defaultOutboundRules, partnerReferenceNumberRequiredRule];

export const transactionOutboundShippingFormRules: Array<ValidationRules<Transaction>> = [
    fromLocationRequiredRule,
    toLocationRequiredRule,
    {
        key: 'transactionLines',
        name: 'core.domain.transactionLines',
        required: true,
        rules: [
            oneItemIsRequiredRule,
            (value: Array<TransactionLine>) => {
                const errMsg = getTranslation('core.validation.youMustEnterActualQuantityForAtLeast1ItemInOrderToShip');
                return value.some((l) => l.actualQuantity > 0) || errMsg;
            },
            quantitiesMustExceedScannedTagsRule,
        ],
    },
    shipFromInventoryCategoryRequiredRule,
    partnerReferenceNumberRequiredRule,
];

export const transactionOutboundScheduledFormRules: Array<ValidationRules<Transaction>> = [...defaultOutboundRules, shipFromInventoryCategoryRequiredRule];

export const transactionOrderFormRules: Array<ValidationRules<Transaction>> = [
    toLocationRequiredRule,
    {
        key: 'dateRequired',
        name: 'core.domain.dateRequired',
        required: true,
        rules: [
            (value: Date) => getNumberOfDays(new Date(), value) >= orderDateRangeStart || getTranslation('core.validation.dateRequiredMustBeWithinPEMSettingsRange', orderDateRangeStart, orderDateRangeEnd),
            (value: Date) => getNumberOfDays(new Date(), value) <= orderDateRangeEnd || getTranslation('core.validation.dateRequiredMustBeWithinPEMSettingsRange', orderDateRangeStart, orderDateRangeEnd),
        ],
    },
    partnerReferenceNumberRequiredRule,
    {
        key: 'transactionLines',
        name: 'core.domain.transactionLines',
        required: true,
        rules: [oneItemIsRequiredRule],
    },
];

export const transactionCloseoutDisputedFormRules: Array<ValidationRules<Transaction>> = [
    {
        key: 'transactionLines',
        name: 'core.domain.transactionLines',
        required: true,
        rules: [
            (value: Array<TransactionLine>, transaction: Transaction) => {
                if (transaction.status === TransactionStatus.DISPUTED) {
                    return !value.some((line) => line.actualQuantity !== line.receivedQuantity) || getTranslation('core.validation.receivedQuantitiesAreNotTheSameAsActualQuantities');
                }

                return true;
            },
        ],
    },
];

export const transactionDisputeFormRules: Array<ValidationRules<Transaction>> = [
    {
        key: 'disputeNotes',
        name: 'core.domain.disputeNotes',
        required: true,
    },
    {
        key: 'transactionLines',
        name: 'core.domain.transactionLines',
        required: true,
        rules: [oneItemIsRequiredRule],
    },
];

export const ftShippingTransactionFormRules: Array<ValidationRules<Transaction>> = [
    toLocationRequiredRule,
    partnerReferenceNumberRequiredRule,
    {
        key: 'transactionLines',
        name: 'core.domain.transactionLines',
        required: true,
        rules: [oneItemIsRequiredRule, oneLineMustHaveActualQuantityRule],
    },
];
