import Item from '@/domain/Item';
import Carrier from '@/domain/Carrier';
import LocationType from '@/domain/LocationType';
import ItemType from '@/domain/ItemType';
import TrailerType from '@/domain/TrailerType';
import { EMAIL_REGEX, URL_REGEX } from '@/validation/utils';
import { ValidationRules } from '@/validation/types';
import CycleCountPreferences from '@/domain/cyclecount/CycleCountPreference';
import FreightCode from '@/domain/FreightCode';
import UnitLoadPart from '@/domain/UnitLoadPart';
import { useChildValidator } from '@/validation/useValidator';
import LocationRouteConfig from '@/domain/LocationRouteConfig';
import InventoryCategory from '@/domain/InventoryCategory';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import Setting from '@/domain/Setting';
import EquipmentType from '@/domain/EquipmentType';
import Repair from '@/domain/Repair/Repair';
import RepairPartQuantity from '@/domain/Repair/RepairPartQuantity';

export const itemRules: Array<ValidationRules<Item>> = [
    {
        key: 'length',
        name: 'core.domain.length',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.lengthCannotBeZeroOrNegative')],
    },
    {
        key: 'width',
        name: 'core.domain.width',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.widthCannotBeZeroOrNegative')],
    },
    {
        key: 'height',
        name: 'core.domain.height',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.heightCannotBeZeroOrNegative')],
    },
    {
        key: 'collapsedHeight',
        required: false,
        rules: [
            (value: number, form: any) => {
                if (form.collapsible && value < 1) {
                    return getTranslation('core.validation.whenTheCollapsibleCheckBoxIsCheckedCollapsedHeightIsRequired');
                }
                return true;
            },
        ],
    },
    {
        key: 'weight',
        name: 'core.domain.weight',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.weightCannotBeZeroOrNegative')],
    },
    {
        key: 'name',
        name: 'core.domain.name',
        required: true,
        rules: [(value: string) => value.length <= 255 || getTranslation('core.validation.nameIsTooLong')],
    },
    {
        key: 'shortName',
        name: 'core.domain.shortName',
        required: true,
        rules: [(value: string) => value.length <= 255 || getTranslation('core.validation.shortNameIsTooLong')],
    },
    {
        key: 'palletQty',
        name: 'core.domain.palletQty',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.palletQtyCannotBeZeroOrNegative')],
    },
    {
        key: 'type',
        name: 'core.domain.type',
        required: true,
    },
    {
        key: 'customerItemNumber',
        rules: [(value: string) => value.length <= 25 || getTranslation('core.validation.customerItemNumberIsTooLong')],
    },
    {
        key: 'unitLoadParts',
        child: () => useChildValidator<UnitLoadPart>('combined-item-part', 'partId'),
        rules: [
            (value: Array<UnitLoadPart>) => value.length >= 2 || getTranslation('core.validation.mustDefineAtLeast2ComponentContainers'),
            (value: Array<UnitLoadPart>) => value.filter((part) => part.isPrimaryPlanningItem).length === 1 || getTranslation('core.validation.unitLoadMustHaveOnePrimaryPlanningItem'),
        ],
        required: 'isUnitLoad',
    },
    {
        key: 'floorLocation',
        name: 'core.domain.floorLocation',
        rules: [(value?: string) => (value && value.length <= 100) || getTranslation('core.validation.floorLocationIsTooLong')],
    },
];

export const unitLoadPartRules: Array<ValidationRules<UnitLoadPart>> = [
    {
        key: 'quantity',
        name: 'core.domain.quantity',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.quantityCannotBeZeroOrNegative')],
    },
];

export const carrierFormRules: Array<ValidationRules<Carrier>> = [
    {
        key: 'name',
        name: 'core.domain.name',
        required: true,
    },
    {
        key: 'shortName',
        name: 'core.domain.shortName',
        required: true,
    },
    {
        key: 'phone',
        name: 'core.domain.phone',
        required: true,
        rules: [(value: string) => value.length >= 7 || getTranslation('core.validation.phoneMustBeAtLeast7Characters')],
    },
    {
        key: 'email',
        name: 'core.domain.email',
        required: true,
        rules: [(value: string) => EMAIL_REGEX.test(value) || getTranslation('core.validation.emailMustBeAValidEmailAddress')],
    },
    {
        key: 'contact',
        name: 'core.domain.contact',
        required: true,
    },
    {
        key: 'scac',
        name: getTranslation('core.domain.scac'),
        required: true,
    },
    {
        key: 'dotRegistration',
        name: 'core.domain.dotRegistration',
        required: true,
    },
    {
        key: 'companyUrl',
        required: false,
        rules: [(value: string) => URL_REGEX.test(value) || getTranslation('core.validation.companyUrlIsNotValid')],
    },
    {
        key: 'companyLogoUrl',
        required: false,
        rules: [(value: string) => URL_REGEX.test(value) || getTranslation('core.validation.companyLogoUrlIsNotValid')],
    },
];

export const locationTypeFormRules: Array<ValidationRules<LocationType>> = [
    {
        key: 'description',
        name: 'core.domain.description',
        required: true,
        rules: [(value: string) => value.length <= 50 || getTranslation('core.validation.descriptionShouldNotExceed50Characters')],
    },
];

export const CycleCountPreferenceRules: Array<ValidationRules<CycleCountPreferences>> = [
    {
        key: 'frequency',
        name: 'core.domain.frequency',
        required: true,
        rules: [(value: number) => value >= 0 || getTranslation('core.validation.frequencyIsRequired')],
    },
    {
        key: 'containersCount',
        required: false,
        rules: [(value: number) => (value > 0 && value <= 50) || getTranslation('core.validation.containersCountMustBeWithinAValidRange')],
    },
    {
        key: 'creationDaysBeforeDue',
        required: false,
        rules: [(value: number) => (value >= 0 && value <= 31) || getTranslation('core.validation.creationDaysBeforeDueMustBeWithinAValidRange')],
    },
    {
        key: 'inventoryAdjustmentThreshold',
        name: 'core.domain.inventoryAdjustmentThreshold',
        required: true,
        // displaying to user as % between 1-100, but storing as a decimal 0-1.  so max value should be 1
        rules: [(value: number) => (value >= 0 && value <= 1) || getTranslation('core.validation.inventoryAdjustmentThresholdMustBeAValidPercentage')],
    },
];

export const itemTypeFormRules: Array<ValidationRules<ItemType>> = [
    {
        key: 'description',
        name: 'core.domain.description',
        required: true,
        rules: [(value: string) => value.length <= 20 || getTranslation('core.validation.descriptionShouldNotExceed20Characters')],
    },
    {
        key: 'repairs',
        name: 'core.domain.repairs',
        required: 'repairable',
        rules: [
            (value: Repair[], form: ItemType) => (
                !form.repairable
                || value.filter((repair) => !repair.deleted).length > 0
                || `${getTitleCaseTranslation('core.domain.repairs')} ${getTranslation('core.validation.isRequired')}`
            ),
        ],
    },
];

export const repairFormRules: Array<ValidationRules<Repair>> = [
    {
        key: 'name',
        name: 'core.domain.repairName',
        required: true,
        rules: [(value: string) => value.length <= 100 || getTranslation('core.validation.repairNameShouldNotExceed100Characters')],
    },
    {
        key: 'description',
        name: 'core.domain.repairDescription',
        required: true,
        rules: [(value: string) => value.length <= 250 || getTranslation('core.validation.repairDescriptionShouldNotExceed250Characters')],
    },
    {
        key: 'repairPartQuantities',
        name: 'core.domain.repairPartQuantities',
        required: true,
        rules: [
            (value: RepairPartQuantity[]) => value.length > 0 || getTranslation('core.validation.repairDoesNotHaveAnyRepairParts'),
            (value: RepairPartQuantity[]) => {
                const repairPartIdSet = new Set(value.map((repairPartQty) => repairPartQty.repairPartId));

                return value.length === repairPartIdSet.size || getTranslation('core.validation.repairPartsMustBeUnique');
            },
        ],
    },
];

export const repairPartQuantityFormRules: Array<ValidationRules<RepairPartQuantity>> = [
    {
        key: 'repairPartId',
        name: 'core.domain.repairPart',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.repairPartMustBeSelected')],
    },
];

export const freightCodeFormRules: Array<ValidationRules<FreightCode>> = [
    {
        key: 'description',
        name: 'core.domain.description',
        required: true,
        rules: [(value: string) => value.length <= 50 || getTranslation('core.validation.descriptionShouldNotExceed50Characters')],
    },
];

export const trailerTypeFormRules: Array<ValidationRules<TrailerType>> = [
    {
        key: 'name',
        name: 'core.domain.name',
        required: true,
    },
    {
        key: 'shortName',
        name: 'core.domain.shortName',
        required: true,
    },
    {
        key: 'height',
        name: 'core.domain.height',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.heightCannotBeZeroOrNegative')],
    },
    {
        key: 'width',
        name: 'core.domain.width',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.widthCannotBeZeroOrNegative')],
    },
    {
        key: 'length',
        name: 'core.domain.length',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.lengthCannotBeZeroOrNegative')],
    },
    {
        key: 'cubicVolume',
        name: 'core.domain.cubicVolume',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.cubicVolumeCannotBeZeroOrNegative')],
    },
    {
        key: 'loadType',
        name: 'core.domain.loadType',
        required: true,
    },
];

export const locationRouteConfigFormRules: Array<ValidationRules<LocationRouteConfig>> = [
    {
        key: 'shipFromInventoryCategories',
        name: 'core.domain.shipFromInventoryCategories',
        required: true,
        rules: [(value: Array<InventoryCategory>) => value?.length > 0 || getTranslation('core.validation.inventoryCategoryMustBeDefined')],
    },
    {
        key: 'minTrailerPercent',
        name: 'core.domain.minTrailerPercent',
        required: 'shippingLoadTypeId',
        rules: [(value: number) => (value > 0 && value < 100) || getTranslation('core.validation.minTrailerPercentMustBeBetweenOneAnd99')],
    },
    {
        key: 'maxLTLDayRange',
        name: getTranslation('core.domain.maxLTLDayRange'),
        required: 'isLTL',
        rules: [(value: number) => value > 0 || getTranslation('core.validation.maxLTLDayRangeMustBeGreaterThanZero')],
    },
];

export const customerSettingsRules: Array<ValidationRules<Setting>> = [
    {
        key: 'value',
        name: 'core.common.value',
        rules: [(value: string) => value.length <= 256 || getTranslation('core.validation.valueIsTooLong')],
    },
];

export const equipmentTypeConfigFormRules: Array<ValidationRules<EquipmentType>> = [
    {
        key: 'description',
        name: 'core.domain.description',
        required: true,
        rules: [(value: string) => value.length <= 50 || getTranslation('core.validation.descriptionShouldNotExceed50Characters')],
    },
];
