import {
    newLocationFormRules,
    updateLocationFormRules,
    updateLocationAddressFormRules,
    dockFormRules,
    floorLocationFormRules,
} from '@/validation/form-rules/location';
import {
    existingReceivingShipmentFormRules,
    scheduledShipmentFormRules,
    shipmentFormRules,
    ftShippingShipmentFormRules,
    ftShippingShipmentFormSaveOnlyRules,
    pemSupplierShippingFormRules,
    newReceivingShipmentFormRules,
} from '@/validation/form-rules/shipment';
import { applicationUserFormRules, roleRules } from '@/validation/form-rules/user';
import {
    ftShippingTransactionFormRules,
    transactionCloseoutDisputedFormRules,
    transactionDisputeFormRules,
    transactionOrderFormRules,
    transactionOutboundFormRules,
    transactionOutboundScheduledFormRules,
    transactionOutboundShippingFormRules,
} from '@/validation/form-rules/transaction';
import warehouseMovementRules from '@/validation/form-rules/warehouseMovement';
import {
    carrierFormRules,
    CycleCountPreferenceRules,
    itemRules,
    unitLoadPartRules,
    itemTypeFormRules,
    locationTypeFormRules,
    trailerTypeFormRules,
    freightCodeFormRules,
    locationRouteConfigFormRules,
    customerSettingsRules,
    equipmentTypeConfigFormRules,
    repairFormRules,
    repairPartQuantityFormRules,
} from '@/validation/form-rules/config';
import { createTrackedItemRules, updateTrackedItemRules } from '@/validation/form-rules/trackedItem';
import { FormValidationKey, RuleResult, ValidationRules } from '@/validation/types';
import productionPartAddRules from '@/validation/form-rules/Pfep/productionPart';
import plannedPartAddRules from '@/validation/form-rules/Pfep/plannedPart';
import sortStationFormRules from '@/validation/form-rules/sortStation';
import equipmentFormRules from '@/validation/form-rules/equipment';
import { repairPartFormRules, repairPartInventoryFormRules } from '@/validation/form-rules/repair/index';
import itemTransformationFormRules from '@/validation/form-rules/inventory';
import newPlannedReservationRules from '@/validation/form-rules/reservation';

export const EMAIL_REGEX = /\S+@\S+\.\S+/;
export const CONTAINS_SPECIAL_CHARS_REGEX = /[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/;
export const CONTAINS_NO_WHITE_SPACE_REGEX = /^\S*$/;
export const URL_REGEX = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const ALPHANUMERIC_REGEX = /^[a-z0-9\s]+$/i;

export function getValidationRules(key: FormValidationKey): Array<ValidationRules<any>> {
    switch (key) {
    case 'new-location':
        return newLocationFormRules;
    case 'update-location':
        return updateLocationFormRules;
    case 'update-location-address':
        return updateLocationAddressFormRules;
    case 'shipment':
        return shipmentFormRules;
    case 'application-user':
        return applicationUserFormRules;
    case 'role':
        return roleRules;
    case 'item':
        return itemRules;
    case 'existing-receiving-shipment':
        return existingReceivingShipmentFormRules;
    case 'new-receiving-shipment':
        return newReceivingShipmentFormRules;
    case 'ft-shipping-shipment':
        return ftShippingShipmentFormRules;
    case 'ft-shipping-shipment-save-only':
        return ftShippingShipmentFormSaveOnlyRules;
    case 'carrier':
        return carrierFormRules;
    case 'location-type':
        return locationTypeFormRules;
    case 'cycle-count-preferences':
        return CycleCountPreferenceRules;
    case 'item-type':
        return itemTypeFormRules;
    case 'trailer-type':
        return trailerTypeFormRules;
    case 'transaction-outbound':
        return transactionOutboundFormRules;
    case 'transaction-outbound-shipping':
        return transactionOutboundShippingFormRules;
    case 'transaction-order':
        return transactionOrderFormRules;
    case 'transaction-dispute':
        return transactionDisputeFormRules;
    case 'transaction-outbound-scheduled':
        return transactionOutboundScheduledFormRules;
    case 'transaction-closeout-dispute':
        return transactionCloseoutDisputedFormRules;
    case 'scheduled-shipment':
        return scheduledShipmentFormRules;
    case 'warehouse-movement':
        return warehouseMovementRules;
    case 'freight-code':
        return freightCodeFormRules;
    case 'create-tracked-item':
        return createTrackedItemRules;
    case 'update-tracked-item':
        return updateTrackedItemRules;
    case 'dock':
        return dockFormRules;
    case 'combined-item-part':
        return unitLoadPartRules;
    case 'production-part-add':
        return productionPartAddRules;
    case 'planned-part-add':
        return plannedPartAddRules;
    case 'sort-station':
        return sortStationFormRules;
    case 'location-route-config':
        return locationRouteConfigFormRules;
    case 'pem-supplier-shipping':
        return pemSupplierShippingFormRules;
    case 'ft-shipping-transactions':
        return ftShippingTransactionFormRules;
    case 'customer-settings':
        return customerSettingsRules;
    case 'equipment-type':
        return equipmentTypeConfigFormRules;
    case 'equipment':
        return equipmentFormRules;
    case 'repair':
        return repairFormRules;
    case 'repair-part':
        return repairPartFormRules;
    case 'repair-part-inventory':
        return repairPartInventoryFormRules;
    case 'repair-part-quantity':
        return repairPartQuantityFormRules;
    case 'item-transformation':
        return itemTransformationFormRules;
    case 'new-planned-reservation':
        return newPlannedReservationRules;
    case 'floor-location':
        return floorLocationFormRules;
    default:
        throw new Error(`No matching form validation for key: ${key}`);
    }
}

export function nullableValueRuleFunction<T>(value: T | undefined, conditionIfValueIsNotNull: (value: T) => RuleResult): RuleResult {
    if (value) {
        return conditionIfValueIsNotNull(value);
    }

    return true;
}
